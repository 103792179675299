import * as React from 'react';
import logo from '@image/LandingLogo.png';
import './App.scss';
import { useEffect, useState } from 'react';

import SaltHomeVideo from './assets/SaltHome.mov'
import AudioDevotionalVideo from './assets/AudioDevotional.mov'
import EventsVideo from './assets/Events.mov'
import ChurchGoalsLogo from '@image/ChurchGoalsLogo.png'

function App(props: {
  churchGoals: boolean
}) {
  const [saltLogoLoaded, setSaltLogoLoaded] = useState(false)
  const [introAnimationStage, setIntroAnimationStage] = useState("animation-zero")

  useEffect(() => {
    if (!saltLogoLoaded) return;

    setTimeout(() => {
      setIntroAnimationStage("animation-one");

      setTimeout(() => {
        setIntroAnimationStage("animation-two");
      }, 650);

      setTimeout(() => {
        setIntroAnimationStage("animation-three");
      }, 1150);

      setTimeout(() => {
        setIntroAnimationStage("");
      }, 1750);
    }, 100);
  }, [saltLogoLoaded]);

  return (
    <div className="App">
      <div className="intro-holder">
        <div className={`content ${introAnimationStage}`}>
          <div className='mast'>
            <h1 className='mast-text'>
              🎉 Meet
            </h1>
            <img src={logo}
              className="logo"
              draggable={false}
              alt="Salt"
              onLoad={() => setSaltLogoLoaded(true)} />
            <h1 className='mast-text' style={{ paddingTop: 16 }}>,</h1>
          </div>
          <h1>Your church's best friend.</h1>
        </div>
      </div>

      <div className="beliefs-holder">
        <h1>We believe the church doesn't have the right tools to connect with their members.</h1>

        <h2>We're here to change that.</h2>
      </div>

      {
        props.churchGoals ?
          <div className='church-goals'>
            <img src={ChurchGoalsLogo} draggable={false} alt="Church Goals Logo" />
            <h2>Coming from Church Goals?</h2>
            <p>We have a special link for you to use when you sign up.</p>

            <p><a href="https://tally.so/r/w7RVbz">Click here</a>, or use any other sign up link on this page.</p>
          </div> :
          null
      }

      <div className="tech-intro-holder">
        <p>
          Salt is a church companion for your members, and a toolbox for your staff.
          <br />
          <br />
          Let's take a look.
        </p>
      </div>

      <div className='feature feature-reverse' style={{ marginTop: 128 }}>
        <div>
          <h1>Daily Devotionals</h1>
          <p>
            We've heard from churchgoers that they want a deeper connection with their pastor.
            <br />
            <br />
            You can create a video, audio, or text devotional so your members start their day off right.
          </p>
        </div>

        <video src={AudioDevotionalVideo} autoPlay={true} muted={true} loop={true} playsInline={true} preload={"none"} />
      </div>

      <div className='feature' style={{ marginTop: 128 }}>
        <div>
          <h1>Sermon Notes & Worship Songs.</h1>
          <p>
            On the weekend, your members can view your sermon notes and worship songs right from the app.
            <br />
            <br />
            And Salt will find your worship songs on Apple Music and Spotify, so your members can listen to them during the week.
          </p>
        </div>

        <video src={SaltHomeVideo} autoPlay={true} muted={true} loop={true} playsInline={true} preload={"none"} />
      </div>

      <div className='feature feature-reverse' style={{ marginTop: 128 }}>
        <div>
          <h1>Events</h1>
          <p>
            Help your church find and join your events outside of Sunday.
            <br />
            <br />
            Your members can view your events, along with their date & time, location, and a link to RSVP.
          </p>
        </div>

        <video src={EventsVideo} autoPlay={true} muted={true} loop={true} playsInline={true} preload={"none"} />
      </div>

      {/* <div className='feature-extra' style={{ marginTop: 84 }}>
        <h1>If you're currently uploading one video a day to Facebook and YouTube, using Salt will save you several hours every month.</h1>
      </div> */}

      <div className='feature' style={{ marginTop: 128 }}>
        <div>
          <h1 className='full'>What's Coming?<br />So Much More.</h1>
          <h3>Automatic Check-In. Small Group Chats. & More.
            <br />
            <br />
            This is only the beginning. Have an amazing idea for us? Need a feature or tool?</h3>

          <a href="mailto:jordan@saltplatform.com?subject=Salt Feedback">We'd love to hear from you. Let's connect.</a>
        </div>
      </div>

      <div className='beta' style={{ marginTop: 82, paddingTop: 32, paddingBottom: 64 }}>
        <div className='container'>
          <h1 className='copy'>
            Slow and steady wins the race.
            <br />
            <br />
            We're focused on building features one at a time, so they're perfect for our church partners.
            <br />
            <br />
            As we build out our initial product with you, we're offering Salt at a discounted rate.
            <br />
            <br />
            Join us now and help us shape the future of tech in the Church.
          </h1>

          <div className='pricing'>
            <h1>$19/mo</h1>
            <h2>During Beta Period</h2>

            <button className='rounded'>
              <a href={props.churchGoals ? "https://tally.so/r/w7RVbz" : "https://tally.so/r/wA7rMD"}>Sign Up</a>
            </button>
          </div>

          <h3>As we add more features, our beta price will increase until we're fully launched. Join us now to lock in a rate for the length of the beta, and for 3 months after its conclusion.</h3>
        </div>
      </div>

      <div className='feature' style={{ marginTop: 128 }}>
        <div>
          <h1 className='full'>Have Questions?<br />Want a Closer Look?</h1>
          <h3>
            We're happy to give your Church a demo of all that Salt has to offer.
            <br />
          </h3>

          <a href="mailto:jordan@saltplatform.com?subject=Salt Live Demo">Schedule a Live Demo</a>
        </div>
      </div>

      <footer>
        <p>Copyright &copy; {new Date().getFullYear()} Salt Technologies, LLC. A Boise, ID Company.</p>
      </footer>
    </div>
  );
}

export default App;
